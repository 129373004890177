.component-overwiev {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;

    margin: auto;

    .component-header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .title {
            font-size: calc(6px + 2vmin);
        }
    }
    
    .process {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
    }
    .result {
        margin: 15px auto;
        .detail{
            text-align: right;
            padding-left: 40px;
            padding-right: 40px;
        }
    }


    .list {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 40vmin;
        overflow-y: auto;
        margin: auto;
        font-size: calc(2px + 2vmin);

        
        .requestCell {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 10px auto;
        }
    }
}